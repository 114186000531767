import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from 'store2'; // Supongamos que usas `store2` para manejar el almacenamiento

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Guard de navegación para proteger rutas
router.beforeEach((to, from, next) => {
  const token = store.get('token');
  const roles = store.get('roles');

  // Si el usuario está logueado y está en la ruta raíz ("/")
  if (token) {
    let role = roles || [];

    if (Array.isArray(role) && role.includes('Company')) {
      if (to.path === '/') return next('/config-recruiter-profile'); // Redirigir a la ruta de Company
    } else if (Array.isArray(role) && role.includes('Candidate')) {
      if (to.path === '/') return next('/config-profile'); // Redirigir a la ruta de Candidate
    } else if (Array.isArray(role) && role.includes('Mentor')) {
      if (to.path === '/') return next('/config-profile-coach'); // Redirigir a la ruta de Mentor
    } else {
      console.error("Rol desconocido, verifica los valores en localStorage.");
      return next({ name: 'notFound' }); // Redirigir si el rol es desconocido
    }
  }

  // Si la ruta no requiere autenticación, permite continuar
  if (!to.meta.requiresAuth) {
    return next();
  }

  // Si la ruta requiere autenticación
  if (!token) {
    // Si no hay token, redirige al login
    return next({ name: 'signIn' });
  }

  // Si se especifica un rol y el usuario no tiene el rol requerido
  if (to.meta.role && (!roles || !roles.includes(to.meta.role))) {
    // Redirigir a la página de acceso denegado o similar
    return next({ name: 'notFound' });
  }

  next(); // Si todo está bien, continúa
});

export default router;