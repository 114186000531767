import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import store from 'store2';
import { decodeJWT } from '@/utils/decodeJWT';  // Asegúrate de tener esta utilidad para decodificar JWT

export function useSessionManager() {
  const sessionTimeout = ref(null);
  const expirationWarningTimeouts = ref([]);  // Array to handle multiple warning timeouts
  const countdownInterval = ref(null);  // For the 10-second countdown
  const router = useRouter();
  
  const setSessionTimeouts = (token) => {
    const decodedToken = decodeJWT(token);
    const exp = decodedToken.exp * 1000;  // Exp time is in seconds, so convert to milliseconds
    const currentTime = Date.now();
    const timeLeft = exp - currentTime;

    if (timeLeft <= 0) {
      logout();
      console.log('Session expired');
    } else {
      // Set timeout to log out the user when the token expires
      sessionTimeout.value = setTimeout(() => {
        logout();
      }, timeLeft);

      [5, 4, 3, 2, 1].forEach((minute) => {
        if (timeLeft > minute * 60000) {
          const warningTimeout = setTimeout(() => {
            notifySessionExpiring(minute);
          }, timeLeft - minute * 60000);
          expirationWarningTimeouts.value.push(warningTimeout);
        }
      });

      // If there are less than 10 seconds, trigger the countdown immediately
      if (timeLeft > 10000) {
        setTimeout(() => {
          startCountdown(10);
        }, timeLeft - 10000);
      } else {
        startCountdown(Math.floor(timeLeft / 1000));
      }
    }
  };

  const notifySessionExpiring = (minutesLeft) => {
    ElMessage.warning(`Tu sesión expirará en ${minutesLeft} minutos. Guarda tus cambios.`);
  };

  const startCountdown = (seconds) => {
    ElMessage.info(`Tu sesión expirará en ${seconds} segundos.`);
    countdownInterval.value = setInterval(() => {
      seconds--;
      if (seconds > 0) {
        ElMessage.info(`Tu sesión expirará en ${seconds} segundos.`);
      } else {
        clearInterval(countdownInterval.value);
      }
    }, 1000);
  };

  const clearSessionTimeouts = () => {
    clearTimeout(sessionTimeout.value);
    expirationWarningTimeouts.value.forEach(timeout => clearTimeout(timeout));
    clearInterval(countdownInterval.value);
  };

  const logout = () => {
    clearSessionTimeouts();
    store.clearAll();
    router.push({ name: 'signIn' });
    ElMessage.info('Tu sesión ha expirado, por favor vuelve a iniciar sesión.');
  };

  const initializeSessionManager = () => {
    const token = store.get("token");
    if (token) {
      setSessionTimeouts(token);
    }
  };

  return {
    initializeSessionManager,
    clearSessionTimeouts,
    logout
  };
}
