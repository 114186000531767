const routes = [
  //No auth pages
  {
    path: "/",
    name: "signIn",
    component: () => import("../pages/auth/SsLogin.vue"),
  },

  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import("../pages/auth/SsForgottenPassword.vue"),
  },

  {
    path: "/companies",
    name: "companies",
    component: () => import("../pages/auth/SsCompanies.vue"),
  },

  {
    path: "/persons",
    name: "persons",
    component: () => import("../pages/auth/SsPersons.vue"),
  },
  {
    path: "/coach-register",
    name: "coach-register",
    component: () => import("../pages/auth/SsCoach.vue"),
  },

  {
    path: "/coach/:slug",
    name: "coach",
    component: () => import("../pages/coach/SsCoach.vue"),
  },

  {
    path: "/coachs",
    name: "coachs",
    component: () => import("../pages/coach/SsCoachs.vue"),
  },

  // Página no encontrada (404)
  {
    path: "/:pathMatch(.*)*", // Captura rutas no encontradas
    name: "notFound",
    component: () => import("../pages/NotFound.vue"),
  },
  //Coach Pages
  {
    path: "/config-profile-coach",
    name: "configProfileCoach",
    component: () => import("../pages/coach/SsConfigProfile.vue"),
    meta: { requiresAuth: true, role: "Mentor" }, // Protegida por autenticación
  },

  // Candidate Pages
  {
    path: "/config-profile",
    name: "configProfile",
    component: () => import("../pages/applicant/SsConfigProfile.vue"),
    meta: { requiresAuth: true, role: "Candidate" }, // Protegida por autenticación
  },

  {
    path: "/my-applications",
    name: "myApplications",
    component: () => import("../pages/applicant/SsMyApplications.vue"),
    meta: { requiresAuth: true, role: "Candidate" },
  },

  // Recruiter Pages
  {
    path: "/config-recruiter-profile",
    name: "configRecruiterProfile",
    component: () => import("../pages/recruiter/SsConfigRecruiterProfile.vue"),
    meta: { requiresAuth: true, role: "Company" },
  },

  {
    path: "/create-offer",
    name: "createOffer",
    component: () => import("../pages/recruiter/SsCreateOffer.vue"),
    meta: { requiresAuth: true, role: "Company" },
  },

  {
    path: "/offers",
    name: "offers",
    component: () => import("../pages/recruiter/SsOffers.vue"),
    meta: { requiresAuth: true, role: "Company" },
  },

  {
    path: "/offer/:id",
    name: "offer",
    component: () => import("../pages/recruiter/SsOffer.vue"),
    meta: { requiresAuth: true, role: "Company" },
    props: true, // Habilitar recibir el ID como prop
  },

  {
    path: "/candidates/:id",
    name: "candidates",
    component: () => import("../pages/recruiter/SsCandidates.vue"),
    meta: { requiresAuth: true, role: "Company" },
  },

  {
    path: "/candidate-details/:id",
    name: "candidateDetails",
    component: () => import("../pages/recruiter/SsCandidateDetails.vue"),
    meta: { requiresAuth: true, role: "Company" },
    props: true,
  },

  {
    path: "/home-offers",
    name: "homeOffers",
    component: () => import("../pages/recruiter/SsHomeOffers.vue"),
    meta: { requiresAuth: true, role: "Company" },
  },

  //Candidate and Companie Pages
  {
    path: "/opportunity-market",
    name: "opportunityMarket",
    component: () => import("../pages/applicant/SsOpportunityMarket.vue"),
  },

  {
    path: "/offer-detail/:id",
    name: "offerDetail",
    component: () => import("../pages/applicant/SsOfferDetail.vue"),
    props: true,
  },

  {
    path: "/successRegister",
    name: "successRegister",
    component: () => import("../pages/applicant/SsSuccessRegister.vue"),
  },
];

export default routes;
